import { ReactElement } from 'react';
import { DOWNLOAD_ON_APP_STORE_SRC, DOWNLOAD_ON_PLAY_STORE_SRC } from '../resourceUrls';
import { PAGE_URL } from '../constants';
import { trackPageView } from '../utils/appAnalyticsUtils';
import { CabButton } from '@CabComponents';
import { useMountEffect } from '../utils/hooks';
import CabinetPage from '../components/Common/CabinetPage';
import { Box, Typography } from '@mui/material';
import { isPlatform } from '../utils/platformUtils';


export const UpdateApp = (): ReactElement => {
  const pageName = 'UpdateApp';
  useMountEffect(() => {
    trackPageView(pageName);
  });

  const ios = isPlatform('ios') && isPlatform('mobile');
  const android = isPlatform('android') && isPlatform('mobile');
  
  const updateWebApp = (): void => {
    // Using true as an argument here was supposed to force a hard-refresh, but this seems to have been
    //   deprecated in modern browsers. The best we can do is hope that the browser will pull the latest version
    //window.location.reload(true);
    window.location.reload();
    if (window.history.state?.referrer) {
      window.location.replace(window.history.state?.referrer);
    }
    window.location.replace(PAGE_URL.DASHBOARD);
  };

  const copy = 'Great news - Cabinet has been updated! Click here to get the latest version:';

  const renderUpdateButton = (): ReactElement => {
    if (android) {
      return <a href={'https://play.google.com/store/apps/details?' + 
          'id=com.joincabinet.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'}>
        <img alt="Get it on Google Play"
          src={DOWNLOAD_ON_PLAY_STORE_SRC}/>
      </a>;
    } else if (ios) {
      return <a href={'https://apps.apple.com/us/app/cabinet-assistants-and-admins/id1469135840'}>
        <img alt="Download on the App Store"
          src={DOWNLOAD_ON_APP_STORE_SRC}/>
      </a>;
    } else {
      // Assume some sort of browser if not android or ios
      return <CabButton onClick={updateWebApp}>
        {'Update Cabinet!'}
      </CabButton>;
    }
  };

  return (
    <CabinetPage
      pageName={'UpdateApp'}
      noHeader
    >
      <Box height='75%' width='100%' display='flex' justifyContent='center' alignItems='center'> 
        <Box width='50%' display='flex' flexDirection='column' gap={2}> 
          <Typography fontSize={20} textAlign='center'>{ copy }</Typography>
          {renderUpdateButton()}
          <Typography fontSize={16} textAlign='center'>
            {'If you tried updating and you are still seeing this page, please wait a few minutes and try '} 
            {'again or e-mail '} 
            <a href="mailto:help@joincabinet.com" target="_blank" rel="noopener noreferrer">
              help@joincabinet.com 
            </a>
            {' for assistance.'} 
          </Typography>

        </Box>
      </Box>
    </CabinetPage>
  );
};

export default UpdateApp;